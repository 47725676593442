/* * start custom tippy simdefi */
.tippy-box[data-theme~=simdefi] {
  @apply rounded-md text-xs text-start font-light border-[1px] border-white-1 bg-bgSecondary;
}

.tippy-content[data-theme~=simdefi] {
  @apply p-2;
}

.tippy-box[data-theme~=simdefi][data-placement^=top]>.tippy-arrow {
  @apply -bottom-[1px];
}

.tippy-box[data-theme~=simdefi][data-placement^=top]>.tippy-arrow:before {
  @apply border-t-white-1;
}

.tippy-box[data-theme~=simdefi][data-placement^=bottom]>.tippy-arrow {
  @apply -top-[1px];
}

.tippy-box[data-theme~=simdefi][data-placement^=bottom]>.tippy-arrow:before {
  @apply border-b-white-1;
}

.tippy-box[data-theme~=simdefi][data-placement^=left]>.tippy-arrow {
  @apply -right-[1px];
}

.tippy-box[data-theme~=simdefi][data-placement^=left]>.tippy-arrow:before {
  @apply border-l-white-1;
}

.tippy-box[data-theme~=simdefi][data-placement^=right]>.tippy-arrow {
  @apply -left-[1px]
}

.tippy-box[data-theme~=simdefi][data-placement^=right]>.tippy-arrow:before {
  @apply border-r-white-1;
}

.tippy-box[data-theme~=simdefi]>.tippy-backdrop {
  @apply bg-white-1;
}

.tippy-box[data-theme~=simdefi]>.tippy-svg-arrow {
  @apply fill-white-1;
}

/* * end custom tippy simdefi */