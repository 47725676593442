@import url('https://fonts.googleapis.com/css2?family=Orbitron&family=Prompt&display=swap');

body {
  margin: 0;
  font-family: 'Prompt', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;



@layer base {
  body {
    @apply text-white-1;
  }

  .card-body .card-detail h1:not(:first-child) {
    @apply mt-8;
  }
}

.react-headless-notifier-fixed {
  z-index: 9999;
}